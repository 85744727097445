var animarOnViewRect = function () {
	// var elementosAnimados 	= document.querySelectorAll('.anima');
	var jaVerificou = false;

	function verificaSeEstaNaViewport() {
		$('[data-animate]').each(function () {

			var animacao = $(this).data('animate');
			var alturaDaViewport = window.innerHeight;
			var positicaoDoElemento = this.getBoundingClientRect().top;

			if (positicaoDoElemento < alturaDaViewport - 100) {
				$(this).addClass(animacao);
			}
		});
	}

	verificaSeEstaNaViewport();
	// Lazyimage.verify();

	$(window).scroll(function () {
		verificaSeEstaNaViewport();
	});
}();