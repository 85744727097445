(function(){

	var botaoTopo = $('[data-irtopo="true"]');

	var alvosAnimacao = $('body,html');

	var topo = $('.topo');

	var alturaTopo = topo.innerHeight() - 27;

	botaoTopo.click(function(){

		alvosAnimacao.animate({
			scrollTop:0,
		},2000, function(){
			botaoTopo.blur();
		});

	});

})();