$('[data-toggle="tab-prod"]').click(function(e){
	e.preventDefault();

	var target 		= $(this).attr('href');
	var deepParents = $(this).parents('.tabs-produto');
	var parents 	= $(this).parents('.nav-tab-prod');
	var siblings 	= parents.find('li');

	siblings.removeClass('active');

	$(this).parent('li').addClass('active');

	deepParents.find('.tab-produto').removeClass('active');
	$(target).addClass('active')
});