let elementosCustom = {
	components : {},
	registrar : function(elemento, ClasseConstrutora){

		let elementos = document.querySelectorAll(elemento);// == $(elemento)

		this.components[elemento] = []; // elelementosCustom.components[elemento] = []

		for(let i=0; i < elementos.length; i++ ){

			let elementoAtualDoMeuFor = elementos[i];

			this.components[elemento].push(new ClasseConstrutora(elementoAtualDoMeuFor));
		}

	}
};

elementosCustom.registrar('icon-awesome', class IconAwesome{
	constructor (elemento){
		let imagem = $(elemento).attr('mask');


		$(elemento).append(this.template(imagem));

	}

	template (imagem){
		return (`
			<div class="icon-awesome" style="mask: url(${imagem}); -webkit-mask: url(${imagem})"></div>
		`)
	};
});


